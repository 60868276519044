import {
  Box,
  IconButton,
  Button,
  Container,
  Grid,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import { ImChrome } from "react-icons/im";

import Logo from 'src/components/Logo';
import Footer from 'src/components/Footer';

const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(50)};
`
);

const TypographyH2 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(17)};
`
);


const ChromeButton = styled(Button)(
  ({ theme }) => `
    background-color: #223354;
`
);
function ChromePreview() {
  return (
    <ChromeButton href="https://chromewebstore.google.com/detail/dailylauncher-custom-new/pdnbdpgpaofgjccejoggaegalheedcaa" startIcon={<ImChrome/>}  sx={{ mt: 2, mb: 10, fontSize: 20 }} color="primary" variant="contained">Get it here for Chrome and Opera!</ChromeButton>
  )
}

function Hero() {
  const loginView = (
    <Container maxWidth="lg" sx={{ textAlign: 'center', marginTop: '50px' }}>
      <Box display="flex" justifyContent="center" alignItems="center" >
        <Logo/>
        <TypographyH1 sx={{ mb: 2, ml: 3, fontSize: {xs: 40, s: 40, m: 60, l: 70, xl: 70}}} variant="h1">
          DailyLauncher
        </TypographyH1>
      </Box>
      <TypographyH2
        sx={{ lineHeight: 1.5, pb: 4 }}
        variant="h4"
        color="text.secondary"
        fontWeight="normal"
      >
        {/* Never lose a link again! */}
      </TypographyH2>

      <Box maxWidth="100%" sx={{ textAlign: 'center', display: 'flex', flexWrap: 'wrap', gridGap: '5%', mb: '100px', mt: '50px', justifyContent: 'center', alignItems: 'center'}}>
        <Box sx={{maxWidth: '30%', minWidth: 400}}>
          <TypographyH2 sx={{ mb: 2, fontSize: {l: 40, md: 40, xs: 30, s: 30, xl: 50} }}>
            A visual organizer for bookmarks
          </TypographyH2>
          <TypographyH2 sx={{ mb: 2 }}>
            Never lose your links again!
          </TypographyH2>
        </Box>
        
        <Box component='img' maxWidth="45%" src="/static/images/placeholders/chrome_banner_1_notext.png" sx={{ m: 0, minWidth: 400}}/>
      </Box>
      <Box maxWidth="100%" sx={{ textAlign: 'center', display: 'flex', flexWrap: 'wrap-reverse', gridGap: '5%', mb: '50px', justifyContent: 'center', alignItems: 'center'}}>
        <Box component='img' maxWidth="60%" src="/static/images/placeholders/chrome_banner_2_notext_slim.png" sx={{ m: 0, minWidth: 500, minWidth: {xs: 400}}}/>
        <Box sx={{maxWidth: '30%', minWidth: 300}}>
          <TypographyH2 sx={{ mb: 2, fontSize: {l: 40, md: 40, xs: 30, s: 30, xl: 50}  }}>
            Drag and drop
          </TypographyH2>
          <TypographyH2 sx={{ mb: 2 }}>
           Unlimited designs from links, bookmarks, and images
          </TypographyH2>
        </Box>
      </Box>

      <ChromePreview/>
      <Footer/>
    </Container>
  )

  return loginView
}

export default Hero;
