import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import Hero from './content/overview/Hero';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages
const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const InfoPrivacy = Loader(lazy(() => import('src/content/pages/Info/Privacy')));


const routes = [
  {
    path: '*',
    element: <Hero />,
    children: [
      {
        path: '/',
        element: <Hero />
      },
      {
        path: 'overview',
        element: (
          <Navigate
            to="/"
            replace
          />
        )
      },
      {
        path: 'info',
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="404"
                replace
              />
            )
          },
          {
            path: 'privacy-policy',
            element: <InfoPrivacy />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      },
    ]
  }
];

export default routes;
