import { Box, Container, Link, Typography, Button, List, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NavLink } from 'react-router-dom';

const FooterWrapper = styled(Box)(
  ({ theme }) => `
        border-radius: 0;
        margin: ${theme.spacing(3)} 0;
`
);

function Footer() {
  return (
    <FooterWrapper sx={{pb: '20px'}}>
      <Container maxWidth="lg">
        <Box
          pt={3}
          display={{ xs: 'block', md: 'flex' }}
          alignItems="center"
          textAlign={{ xs: 'center', md: 'left' }}
          justifyContent="center"
          gap={'10px'}
        >
            <Typography variant="subtitle1">
              &copy; {String(new Date().getFullYear())} - DailyLauncher.
            </Typography>
            <Typography sx={{ pt: { xs: 0, md: 0}, m:0}} variant="subtitle1">
              Made with love by <Link href="https://yury.tech">Yury</Link>
          </Typography>
        </Box>
        <Typography sx={{ pt: { xs: 0, md: 0}}} variant="subtitle1">
          <a href="/privacy.html" rel="noopener noreferrer" underline="none">Privacy Policy</a>
        </Typography>
        
      </Container>
    </FooterWrapper>
  );
}

export default Footer;
