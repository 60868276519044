import { NebulaFighterTheme } from './schemes/NebulaFighterTheme';
import { PureLightTheme } from './schemes/PureLightTheme'
import { CreamyCake } from './schemes/CreamyCake'

export function themeCreator(theme) {
  if (!(theme in themeMap)) {
    return themeMap[NebulaFighterTheme];
  } else return themeMap[theme];
}

export const themeMap = {
  NebulaFighterTheme,
  PureLightTheme,
  CreamyCake
};
