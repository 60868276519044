import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from './base';
import { StylesProvider } from '@mui/styles';


export const ThemeContext = React.createContext((themeName) => { });

const ThemeProviderWrapper = function (props) {


  const theme = themeCreator('PureLightTheme');

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={null}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
