import App from './App';
import ReactDOM from 'react-dom';
import 'src/utils/chart';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';

ReactDOM.render(
  <HelmetProvider>
      <MemoryRouter>
        <App />
      </MemoryRouter>
  </HelmetProvider>,
  document.getElementById('root')
);

//serviceWorker.unregister();
